import { addDays, differenceInDays, format } from "date-fns";
import { forEach, groupBy, map, mapValues, sortBy, sumBy } from "lodash-es";

export default {
  formatSalesRecords(records, dateRange) {
    let formattedRecords = groupBy(
      records.filter(r => r && r.sales_channel),
      r => r.sales_channel.name
    );

    forEach(
      formattedRecords,
      (r, k) =>
        (formattedRecords[k] = groupBy(r, r =>
          format(new Date(r.date_of_sale), "MMMd")
        ))
    );
    const startDate = new Date(dateRange[0]);
    const endDate = new Date(dateRange[1]);

    const daysArray = new Array(
      Math.abs(differenceInDays(startDate, endDate)) + 1
    ).fill(0);

    formattedRecords = mapValues(formattedRecords, obj => {
      const newObj = {};

      daysArray.forEach((_, i) => {
        const date = addDays(startDate, i);
        const form = format(date, "MMMd");
        if (!obj[form]) {
          newObj[form] = [
            {
              net: 0,
              date_of_sale: format(date, "yyyy-MM-dd")
            }
          ];
        } else {
          newObj[form] = obj[form];
        }
      });

      return newObj;
    });

    return map(formattedRecords, (r, sc) => {
      return {
        name: sc,
        data: Object.values(r)
          .map(r => sortBy(r, r => new Date(r.date_of_sale)))
          .map(r => sumBy(r, r => parseInt(r.net)))
      };
    });
  }
};
