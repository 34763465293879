<template>
  <div class="sales-performance" v-if="series.length">
    <ebp-card :loading="loading">
      <h5 class="ml-2 mb-3">{{ $t("daily-eBook-sales-report") }}</h5>
      <apexchart
        width="100%"
        type="area"
        :series="series"
        :options="chartOptions"
        height="300px"
      />
      <router-link
        :to="{ name: $isAdmin ? 'AdminDailySalesReport' : 'DailySalesReport' }"
      >
        <ebp-button class="center-block">See full report </ebp-button>
      </router-link>
    </ebp-card>
  </div>
</template>

<script>
import { add, format, sub, subDays } from "date-fns";

import truncate from "@/helpers/truncate";
import graphHelper from "@/helpers/graph";

const twentyEightDaysAgo = sub(new Date(), { days: 28 });

const twelveElementsEmptyArray = new Array(28).fill(0);
const formatString = "dd-MMM-yyyy";

export default {
  name: "daily-sales-graph",
  data() {
    return {
      dateRange: [
        format(subDays(Date.now(), 28), formatString),
        format(Date.now(), formatString)
      ],
      loading: false,
      chartOptions: {
        colors: this.$colors,
        chart: {
          fontFamily: "SF UI Text",
          toolbar: false
        },
        xaxis: {
          categories: twelveElementsEmptyArray
            .map((_, i) =>
              format(add(twentyEightDaysAgo, { days: i }), "dd-MMM")
            )
            .map(m => this.$t(m))
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2,
          curve: "smooth"
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          },
          style: "hollow"
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function(val) {
                  return val;
                }
              }
            }
          ]
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.6,
            opacityTo: 0.05,
            stops: [42, 100, 100, 100]
          }
        }
      },
      series: []
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    truncate,
    async get() {
      this.loading = true;
      try {
        const res = await this.$store.dispatch("dailySaleRecords/all", {
          date_range: this.dateRange.join(",")
        });

        this.series = graphHelper.formatSalesRecords(res.data, this.dateRange);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    }
  }
};
</script>
